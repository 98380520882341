import React, { useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { Link, useStaticQuery, graphql } from "gatsby"
import Img, { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image"


import { LanguageContext } from '../components/LanguageProvider'
import MainLayout from '../components/mainPageLayout'
import DateReader from '../components/dateReader'
import { Calendar, LocationMarker, LanguageMarker, TimeMarker } from '../components/usefulSVGs'

import * as style from '../styles/allEventPage.module.css'



export default function EventsOverview({selectedEvent}) {
  const dataFull = useStaticQuery(graphql`
    query {
        allCmbPagesJson{
            edges{         
            node{
              genericItems{
                en{
                  seeMore
                }
                hr{
                  seeMore
                }
              }
              eventsPage{
                        en{
                            header
                            nextEvent
                            futureEvents
                            pastEvents
                        }
                        hr {
                            header
                            nextEvent
                            futureEvents
                            pastEvents
                        }
                    }
                }
            }
        }
        allCmbEventsJson(sort: {order: ASC, fields: date}){
          edges {
            node {
              id
              date
              time
              language
              location
              presenter
              jsonId
              en {
                eventMain
              }
              hr {
                eventMain
              }
              
              
            }
          }
        }
      }`)



  let titles = dataFull.allCmbPagesJson.edges[0].node






  return (
    <LanguageContext.Provider value={"hr"}>
      <MainLayout mainHeading={titles.eventsPage}>
        <main className={style.wrap}>
          <EventCategories data={dataFull.allCmbEventsJson.edges} titles = {titles} selectedEvent = {selectedEvent}></EventCategories>
        </main>
      </MainLayout>
    </LanguageContext.Provider>
  )
}


function EventCategories({data, selectedEvent, titles}) {
  let context = useContext(LanguageContext)
  let now = Date.parse(new Date());
  let upcoming = data.filter(event => Date.parse(event.node.date) > now)
  let olderEvents = data.filter(event => Date.parse(event.node.date) < now)

  //hook for seeing more items
  let [nItems, setNItems] = useState(5)

  let dateCompare = (a, b)=>Date.parse(a.node.event) < Date.parse(b.node.event) ? 1 : -1
  let subSet = olderEvents.sort(dateCompare) ///slice(0, nItems)
  let totalItems = olderEvents.length

  let futureEvents = upcoming.map(item => <IndividualEvent data={item} />)
  let pastEvents = subSet.map(item => <IndividualEvent count={nItems} data={item} />)


  let viewMore = () => {
    let newN;
    nItems + 1 > totalItems ? newN = totalItems : newN = nItems + 1;
    setNItems(newN)
  }
let selection;
selectedEvent ===undefined ? 
selection = upcoming :
selection = data.filter(event =>event.node.jsonId===selectedEvent)

  let moreButton;
  nItems !== totalItems ? moreButton = <button className="moreButton" onClick={viewMore}>{titles.genericItems[context].seeMore}</button> : moreButton = "";
  return (<>
  <section className={style.categorySection}>
    <h2 className={style.categoryHeader}>{titles.eventsPage[context].futureEvents}</h2>
    {futureEvents}
    </section>
    <section className={style.categorySection}>
    <h2 className ={style.categoryHeader}>{titles.eventsPage[context].pastEvents}</h2>
    {pastEvents}
    {moreButton}
    </section>
    <NextEvent data = {selection[0]} titles={titles} selected={selectedEvent!==undefined}/>


  </>
  )
}



function IndividualEvent({ data }) {

  let image = getImage(data.node.mainImage) // test for undefined
  let context = useContext(LanguageContext)

  return (
    <article className = {style.eventShortInfo}>
              <Link to={`/events/${data.node.jsonId}`} className={style.linkWrap}>

      <ul className={style.eventBullets}>
        <li><Calendar className={style.eventMarker}></Calendar><DateReader date={data.node.date} /></li>
        <li><TimeMarker className={style.eventMarker}></TimeMarker>{data.node.time}</li>
        <li>
          <LocationMarker className={style.eventMarker} />{data.node.location}</li>
        <li><LanguageMarker className={style.eventMarker} />{context}</li>
      </ul>
      <section className={style.eventHighlights}>
        <ReactMarkdown  components={{ h1: "h3", p: () => false }}>{data.node[context].eventMain}</ReactMarkdown>
        <p className = {style.presenter}><strong>{data.node.presenter}</strong></p>
      </section>
      </Link>
      </article>
  )
}





function NextEvent({data, titles, selected}){
  let context = useContext(LanguageContext);
  let language;


  let title;
  selected ===true?
  title = <h2 className = {style.categoryHeader}>{"Details"}</h2> :
  title = <h2 className = {style.categoryHeader}>{titles.eventsPage[context].nextEvent}</h2>

  let x = (data !=undefined) ? (
  <section className = {style.selectedEvent}>
      {title}
  <section className={style.selectedEventDetails}>
  <ul className={style.eventFlatBullets}>
        <li><Calendar className={style.eventMarker}></Calendar><DateReader date={data?.node.date}/></li>
        <li><TimeMarker className={style.eventMarker}></TimeMarker>{data?.node.time}</li>
        <li>
          <LocationMarker className={style.eventMarker} />{data?.node.location}</li>
        <li><LanguageMarker className={style.eventMarker} />{context}</li>
      </ul>
      <ReactMarkdown className ={style.eventTitle} components={{ h1: "h3", p: () => false }}>{data.node[context].eventMain}</ReactMarkdown>
        <p className = {style.presenter}><strong>{data.node.presenter}</strong></p>
        <ReactMarkdown className ={style.eventTitle} components={{h1:()=>false}}>{data.node[context].eventMain}</ReactMarkdown>

  </section>
  </section>)
   :
 <section className = {style.selectedEvent}><p>{"No Events"}</p></section>


return x
}
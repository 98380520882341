import React, {Component, useContext} from 'react'
import {LanguageContext} from './LanguageProvider'

export default function Date(props){

let date = props.date;

let language = useContext(LanguageContext)
    let year = date.slice(2,4)
    let month = parseInt(date.slice(5,7))
    let day = parseInt(date.slice(8,10))



let sortedDay;
let sortedMonth;
let sortedYear =year;

if (language ==="en"){
    let englishMonths =["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
   sortedMonth =  englishMonths[month-1]



   let sts = [1, 21, 31]
   let nds = [2, 22]
   let rds =[3, 23]

    sortedDay = day + "th"
    switch (day){
        case(sts.indexOf(day) !=-1):
        sortedDay = day+"st"
        break;
        case(nds.indexOf(day) !=-1):
        sortedDay = day+"nd"
        break;
        case(rds.indexOf(day) !=-1):
        sortedDay = day+"rd"
        break;
    }
}

if (language ==="hr"){
    sortedDay = day+".";
    sortedYear = year+".";

    let croatianMonths =["Siječnja", "Veljača", "Ožujka", "Travnja", "Svibnja", "Lipnja", "Sprnja", "Kolovoza", "Rujna", "Listopada", "Studenog", "Prosinca"];
    sortedMonth =  croatianMonths[month-1]

}
    let convertedDate = `${sortedDay} ${sortedMonth} ${sortedYear}`

    return <time>{convertedDate}</time>
}